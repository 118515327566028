import {useEffect, useRef, useState} from "react";
import {Col, Row} from "antd";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import ReactPlayer from "react-player";

const App = () => {
  const [errorIndex, setErrorIndex] = useState([]);

  const [imageKey, setImageKey] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageKey(prevKey => prevKey + 1);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []); // B

  const data = [
    {id:0, title:"Asfalt Plent", link:"rtsp://admin:vguard@78.187.35.82:2220/mode=real&idc=1&ids=2", image:"https://broadcast.orbel.com.tr/memfs/edb40358-af20-44f8-b905-7b92347ba7b2.jpg", hls:"https://broadcast.orbel.com.tr/memfs/edb40358-af20-44f8-b905-7b92347ba7b2.m3u8"},
    {id:1, title:"Asfalt Plent", link:"rtsp://admin:vguard@78.187.35.82:2221/mode=real&idc=1&ids=2", image:'https://broadcast.orbel.com.tr/memfs/11c804bf-f5ad-419d-aec9-e5cc4afbb8c6.jpg', hls:'https://broadcast.orbel.com.tr/memfs/11c804bf-f5ad-419d-aec9-e5cc4afbb8c6.m3u8'},
    {id:2, title:"Asfalt Plent", link:"rtsp://admin:vguard@78.187.35.82:2224/mode=real&idc=1&ids=2", image:'https://broadcast.orbel.com.tr/memfs/31300597-fa47-4d5f-8312-68d3ed0ea267.jpg', hls:'https://broadcast.orbel.com.tr/memfs/31300597-fa47-4d5f-8312-68d3ed0ea267.m3u8'},
    {id:3, title:"Asfalt Plent", link:"rtsp://admin:vguard@78.187.35.82:2228/mode=real&idc=1&ids=2", image: 'https://broadcast.orbel.com.tr/memfs/2482b4d1-733b-44a4-903a-7b2c91566c77.jpg', hls:'https://broadcast.orbel.com.tr/memfs/2482b4d1-733b-44a4-903a-7b2c91566c77.m3u8'},
    {id:4, title:"Asfalt Plent", link:"rtsp://admin:vguard@78.187.35.82:2231/mode=real&idc=1&ids=2", image: 'https://broadcast.orbel.com.tr/memfs/93ba32bc-03cd-4a65-8c20-743fe9b446be.jpg', hls:'https://broadcast.orbel.com.tr/memfs/93ba32bc-03cd-4a65-8c20-743fe9b446be.jpg'},

    {id:5, title:"Besi Çiftliği", link:"rtsp://admin:or225725@5.26.224.6:2238/cam/realmonitor?channel=1&subtype=1", image:'https://broadcast.orbel.com.tr/memfs/a30b3c32-6a12-4bfc-8f5e-be208999ffb9.jpg', hls:'https://broadcast.orbel.com.tr/memfs/a30b3c32-6a12-4bfc-8f5e-be208999ffb9.m3u8'},
    {id:6, title:"Besi Çiftliği", link:"rtsp://admin:ms1234@5.26.224.6:2237/sub", image: 'https://broadcast.orbel.com.tr/memfs/6f062665-bfab-43b0-8072-e64186d5cb01.jpg', hls:'https://broadcast.orbel.com.tr/memfs/6f062665-bfab-43b0-8072-e64186d5cb01.m3u8'},

    {id:6, title:"Cumhuriyet Otoparkı", link:"rtsp://admin:ms1234@78.188.237.68:5555/sub", image: 'https://broadcast.orbel.com.tr/memfs/fcfa361d-6c49-4dc9-91ce-9349586e0cf2.jpg', hls:'https://broadcast.orbel.com.tr/memfs/fcfa361d-6c49-4dc9-91ce-9349586e0cf2.m3u8'},
    {id:7, title:"Cumhuriyet Otoparkı", link:"rtsp://admin:ms1234@78.188.237.68:5554/sub", image:'https://broadcast.orbel.com.tr/memfs/84797ee6-0d90-4500-9219-909ecf2918ca.jpg', hls:'https://broadcast.orbel.com.tr/memfs/84797ee6-0d90-4500-9219-909ecf2918ca.m3u8'},

    {id:8, title:"Teleferik Alt İstasyon", link:"rtsp://admin:or225725@78.187.224.56:5256/cam/realmonitor?channel=1&subtype=1", image:'https://broadcast.orbel.com.tr/memfs/ffe7be7b-8deb-472d-a6b2-9a2dfe2f10bb.jpg', hls:'https://broadcast.orbel.com.tr/memfs/ffe7be7b-8deb-472d-a6b2-9a2dfe2f10bb.m3u8'},
    {id:9, title:"Teleferik Alt İstasyon", link:"rtsp://admin:or225725@78.187.224.56:5259/cam/realmonitor?channel=1&subtype=1", image:'https://broadcast.orbel.com.tr/memfs/af6653c4-1d17-4bec-bf17-e0543a0ebd9f.jpg', hls:'https://broadcast.orbel.com.tr/memfs/af6653c4-1d17-4bec-bf17-e0543a0ebd9f.m3u8'},
    {id:10, title:"Teleferik Alt İstasyon", link:"rtsp://admin:or225725@78.187.224.56:5255/cam/realmonitor?channel=1&subtype=1", image:'https://broadcast.orbel.com.tr/memfs/d833f1c3-59b8-4a28-ad2e-67bcb48d5916.jpg', hls:'https://broadcast.orbel.com.tr/memfs/d833f1c3-59b8-4a28-ad2e-67bcb48d5916.m3u8'},

    {id:11, title:"Teleferik Üst İstasyon", link:"rtsp://admin:ms1234@78.187.18.21:1144/sub", image:'https://broadcast.orbel.com.tr/memfs/f0861177-c5e0-4615-b41d-bb812ea1c91c.jpg', hls:'https://broadcast.orbel.com.tr/memfs/f0861177-c5e0-4615-b41d-bb812ea1c91c.m3u8'},
    {id:12, title:"Teleferik Üst İstasyon", link:"rtsp://admin:ms1234@78.187.18.21:1143/sub", image:'https://broadcast.orbel.com.tr/memfs/a0332a12-0a67-4537-a7af-84e4786fb7e1.jpg', hls:'https://broadcast.orbel.com.tr/memfs/a0332a12-0a67-4537-a7af-84e4786fb7e1.m3u8'},

    {id:13, title:"Çambaşı Kayak Merkezi", link:"rtsp://admin:12ab34cd56@212.156.98.130:561/ch01/1", image:'https://broadcast.orbel.com.tr/memfs/966ca43a-23aa-4b50-8d65-92ddb9f04455.jpg', hls:'https://broadcast.orbel.com.tr/memfs/966ca43a-23aa-4b50-8d65-92ddb9f04455.m3u8'},
    {id:14, title:"Çambaşı Kayak Merkezi", link:"rtsp://admin:12ab34cd56@212.156.98.130:560/ch01/1", image:'https://broadcast.orbel.com.tr/memfs/78f3f18b-4b6c-430a-b9ff-0f1c079fe107.jpg', hls:'https://broadcast.orbel.com.tr/memfs/78f3f18b-4b6c-430a-b9ff-0f1c079fe107.m3u8'},
    {id:15, title:"Çambaşı Kayak Merkezi", link:"rtsp://admin:12ab34cd56@212.156.98.130:559/Streaming/Channels/101", image:'https://broadcast.orbel.com.tr/memfs/01fe4c06-927e-4371-90ab-eaf50a7a43bd.jpg', hls:'https://broadcast.orbel.com.tr/memfs/01fe4c06-927e-4371-90ab-eaf50a7a43bd.m3u8'},
    {id:16, title:"Çambaşı Kayak Merkezi", link:"rtsp://admin:12ab34cd56@212.156.98.130:558/Streaming/Channels/101?transportmode=unicast&profile=Profile_1", image:'https://broadcast.orbel.com.tr/memfs/af01664b-cd0b-4602-b0b6-5aa3c04650b9.jpg', hls:'https://broadcast.orbel.com.tr/memfs/af01664b-cd0b-4602-b0b6-5aa3c04650b9.m3u8'},
    {id:17, title:"Çambaşı Kayak Merkezi", link:"rtsp://admin:or225725@212.156.98.130:562/Streaming/Channels/2/", image:'https://broadcast.orbel.com.tr/memfs/ec3b4216-47ba-4c97-9074-037d654948b9.jpg', hls:'https://broadcast.orbel.com.tr/memfs/ec3b4216-47ba-4c97-9074-037d654948b9.m3u8'},
    {id:18, title:"Çambaşı Kayak Merkezi", link:"rtsp://admin:12ab34cd56@212.156.98.130:556/Streaming/Channels/101?transportmode=unicast&profile=Profile_1", image:'https://broadcast.orbel.com.tr/memfs/88b43882-1636-4138-beb5-223327732faa.jpg', hls:'https://broadcast.orbel.com.tr/memfs/88b43882-1636-4138-beb5-223327732faa.m3u8'},


    {id:19, title:"Fatsa Beton Santrali", link:"rtsp://admin:or225725@5.26.223.207:1197/cam/realmonitor?channel=1&subtype=1", image:'https://broadcast.orbel.com.tr/memfs/7ed2d039-13d6-4174-8577-1da5a69fe23b.jpg', hls:'https://broadcast.orbel.com.tr/memfs/7ed2d039-13d6-4174-8577-1da5a69fe23b.m3u8'},
    {id:20, title:"Fatsa Beton Santrali", link:"rtsp://admin:or225725@5.26.223.207:1198/cam/realmonitor?channel=1&subtype=1", image:'https://broadcast.orbel.com.tr/memfs/21fa81c9-d7a8-4ae2-9379-d0242d876838.jpg', hls:'https://broadcast.orbel.com.tr/memfs/21fa81c9-d7a8-4ae2-9379-d0242d876838.m3u8'},
    {id:21, title:"Fatsa Beton Santrali", link:"rtsp://admin:or225725@5.26.223.207:1199/cam/realmonitor?channel=1&subtype=1", image:'https://broadcast.orbel.com.tr/memfs/204f6551-7a5f-4f2f-99b5-445614b4efe3.jpg', hls:'https://broadcast.orbel.com.tr/memfs/204f6551-7a5f-4f2f-99b5-445614b4efe3.m3u8'},

    {id:22, title:"Ayışığı Otopark", link:"rtsp://admin:Admin123@95.9.207.156:1125/Streaming/channels/2", image:'https://broadcast.orbel.com.tr/memfs/62a8ec86-04a3-465b-9dea-4d65395d487a.jpg', hls:'https://broadcast.orbel.com.tr/memfs/62a8ec86-04a3-465b-9dea-4d65395d487a.m3u8'},
    {id:23, title:"Ayışığı Otopark", link:"rtsp://admin:Admin123@95.9.207.156:1127/Streaming/channels/2", image:'https://broadcast.orbel.com.tr/memfs/78b45b08-a6e6-4c79-a3f9-233c2ecf1491.jpg', hls:'https://broadcast.orbel.com.tr/memfs/78b45b08-a6e6-4c79-a3f9-233c2ecf1491.m3u8'},
    {id:24, title:"Ayışığı Otopark", link:"rtsp://admin:Admin123@95.9.207.156:1118/Streaming/channels/2", image:'https://broadcast.orbel.com.tr/memfs/991593ef-57db-47d0-9bb1-caa99166c569.jpg', hls:'https://broadcast.orbel.com.tr/memfs/991593ef-57db-47d0-9bb1-caa99166c569.m3u8'},
    {id:25, title:"Ayışığı Otopark", link:"rtsp://admin:Admin123@95.9.207.156:1122/Streaming/channels/2", image:'https://broadcast.orbel.com.tr/memfs/58a7bb66-8f9e-4718-87c3-7842d944b6bc.jpg', hls:'https://broadcast.orbel.com.tr/memfs/58a7bb66-8f9e-4718-87c3-7842d944b6bc.m3u8'},
    {id:26, title:"Ayışığı Otopark", link:"rtsp://admin:Admin123@95.9.207.156:1117/Streaming/channels/2", image:'https://broadcast.orbel.com.tr/memfs/8fff1b4e-0b34-4bff-914f-8befc391f0e7.jpg', hls:'https://broadcast.orbel.com.tr/memfs/8fff1b4e-0b34-4bff-914f-8befc391f0e7.m3u8'},

    {id:26, title:"Ters Ev", link:"rtsp://admin:ms1234@88.248.23.247:2241/sub", image:'https://broadcast.orbel.com.tr/memfs/1099afe7-8d5e-4741-823c-447d3b42f972.jpg', hls:'https://broadcast.orbel.com.tr/memfs/1099afe7-8d5e-4741-823c-447d3b42f972.m3u8'},
    {id:26, title:"Ters Ev", link:"rtsp://admin:ms1234@88.248.23.247:2247/sub", image:'https://broadcast.orbel.com.tr/memfs/d1d48eb2-fb7c-408d-88b5-0d7522dcfae9.jpg', hls:'https://broadcast.orbel.com.tr/memfs/d1d48eb2-fb7c-408d-88b5-0d7522dcfae9.m3u8'},
    {id:26, title:"Ters Ev", link:"rtsp://admin:ms1234@88.248.23.247:2248/sub", image:'https://broadcast.orbel.com.tr/memfs/54d60958-8866-4602-a094-81b26f7362f7.jpg', hls:'https://broadcast.orbel.com.tr/memfs/54d60958-8866-4602-a094-81b26f7362f7.m3u8'},
    {id:26, title:"Ters Ev", link:"rtsp://admin:ms1234@88.248.23.247:2240/sub", image:'https://broadcast.orbel.com.tr/memfs/7d2a9009-fe93-4419-8a07-a58129a68f92.jpg', hls:'https://broadcast.orbel.com.tr/memfs/7d2a9009-fe93-4419-8a07-a58129a68f92.m3u8'},
  ]

  const sliderRef = useRef(null);

  return(<div className={"camera-container"}>
    <Row style={{backgroundColor:'white', justifyContent:"center", alignItems:"center",}}>
      <img height={70} style={{padding:10}} src="https://orbel.com.tr/assets/images/logo.png" alt="ORBEL TV"></img>
      <div style={{fontWeight:"bold",fontSize:20}}>| CANLI YAYIN SİSTEMİ</div>
    </Row>
    <Row style={{marginTop:20}}>
      <Col span={24}>
        <Swiper
            ref={sliderRef}
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            // autoplay={{
            //   delay: 60000,
            //   disableOnInteraction: false,
            // }}
            autoplay={false}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
        >
          {data.map((item, index) => {
            return(
                <SwiperSlide key={index}>
                  <div style={{
                    zIndex: -1,
                    position: "absolute",
                    textAlign: "center",
                    marginTop: 0,
                    width: "100%",
                    top: "0",
                    transform: "translateY(-50%)"
                  }}>
                    <div style={{color: "white", position: "relative", display: "block"}}>
                      <div style={{
                        fontSize: 20,
                        height: 1250,
                        width: 725,
                        margin: "auto",
                        backgroundColor: 'black',
                        color:'white',
                        fontWeight: "bold"
                      }}>
                        YAYIN BAĞLANIYOR...
                      </div>
                    </div>
                  </div>
                  <ReactPlayer
                      playing={true}
                      playsinline={true}
                      controls={false}
                      loop={true}
                      muted={true}
                      onError={() => {
                      }}
                      width="100%"
                      height={600}
                      zIndex={99}
                      url={item.hls}

                  />
                  <div style={{
                    position: "absolute",
                    color: 'white',
                    width: "100%",
                    top: 10,
                    fontSize: 20,
                    backgroundColor: '',
                    textAlign: "center"
                  }}>{item.title}</div>
                </SwiperSlide>
            )
          })}
        </Swiper>
      </Col>

      <Col span={24}>
        <Swiper
            slidesPerView={5}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            style={{marginTop:20}}
        >
          {data.map((item, index) => {
            return(
                <SwiperSlide onClick={() => {
                  sliderRef.current.swiper.slideTo(index)
                }} key={index}>
                  <div style={{height: 250, width: "100%"}}>
                    <img height={"100%"}
                         style={{borderRadius: 10,}}
                         width={"100%"}
                         src={`${item.image}?${imageKey}`}
                         alt="ORBEL TV"></img>

                  </div>
                    <div style={{position:"absolute", color:'white', width:"100%", bottom:10, fontSize:16, backgroundColor:'', textAlign:"center"}}>{item.title}</div>
                </SwiperSlide>
            )
          })}
        </Swiper>
      </Col>
    </Row>
  </div>)
}

export default App;
